import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'walkthrough',
    loadChildren: () => import('./pages/walkthrough/walkthrough.module').then( m => m.WalkthroughPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'forgotpassword',
    loadChildren: () => import('./pages/forgotpassword/forgotpassword.module').then( m => m.ForgotpasswordPageModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule)
  },
  {
    path: 'faqs',
    loadChildren: () => import('./pages/faqs/faqs.module').then( m => m.FaqsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule)
  },
  {
    path: 'feedback',
    loadChildren: () => import('./pages/feedback/feedback.module').then( m => m.FeedbackPageModule)
  },
  {
    path: 'changepassword',
    loadChildren: () => import('./pages/changepassword/changepassword.module').then( m => m.ChangepasswordPageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('./pages/notifications/notifications.module').then( m => m.NotificationsPageModule)
  },
  {
    path: 'history',
    loadChildren: () => import('./pages/history/history.module').then( m => m.HistoryPageModule)
  },
  {
    path: 'payments',
    loadChildren: () => import('./pages/payments/payments.module').then( m => m.PaymentsPageModule)
  },
  {
    path: 'ad-details/:id',
    loadChildren: () => import('./pages/ad-details/ad-details.module').then( m => m.AdDetailsPageModule)
  },
  {
    path: 'scanqrcode',
    loadChildren: () => import('./pages/scanqrcode/scanqrcode.module').then( m => m.ScanqrcodePageModule)
  },
  {
    path: 'pin',
    loadChildren: () => import('./pages/pin/pin.module').then( m => m.PinPageModule)
  },
  {
    path: 'effettua-pagamento',
    loadChildren: () => import('./pages/effettua-pagamento/effettua-pagamento.module').then( m => m.EffettuaPagamentoPageModule)
  },  {
    path: 'qrcodefullscr',
    loadChildren: () => import('./pages/qrcodefullscr/qrcodefullscr.module').then( m => m.QrcodefullscrPageModule)
  },
  {
    path: 'inserisci-importo-qty',
    loadChildren: () => import('./pages/inserisci-importo-qty/inserisci-importo-qty.module').then( m => m.InserisciImportoQtyPageModule)
  },
  {
    path: 'my-ads-list',
    loadChildren: () => import('./pages/my-ads-list/my-ads-list.module').then( m => m.MyAdsListPageModule)
  },
  {
    path: 'conferma-pagamento',
    loadChildren: () => import('./pages/conferma-pagamento/conferma-pagamento.module').then( m => m.ConfermaPagamentoPageModule)
  },
  {
    path: 'reporting',
    loadChildren: () => import('./pages/reporting/reporting.module').then( m => m.ReportingPageModule)
  },
  {
    path: 'my-ads-list-user',
    loadChildren: () => import('./pages/my-ads-list-user/my-ads-list-user.module').then( m => m.MyAdsListUserPageModule)
  },
  {
    path: 'my-ad-details',
    loadChildren: () => import('./pages/my-ad-details/my-ad-details.module').then( m => m.MyAdDetailsPageModule)
  },
  {
    path: 'coming-soon',
    loadChildren: () => import('./pages/coming-soon/coming-soon.module').then( m => m.ComingSoonPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'transaction-details',
    loadChildren: () => import('./pages/transaction-details/transaction-details.module').then( m => m.TransactionDetailsPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
